import HtmlParse from 'htmr';

import db from "../../../../data/db.json";

import Header from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";
import PageView from "../../atoms/PageView/PageView";
import Button from "../../atoms/Button/Button";
import { useEffect, useState } from "react";

const PrivacyPage = () => {
  const [privacy, setPrivacy] = useState("")

  const companyName = db.data.company.name;
  const companyAddress = db.data.company.address;
  const companyWebsiteName = db.data.company.website.name;
  const companyWebsiteUrl = db.data.company.website.url;

  const privacyFileLocation = "/data/privacy.txt" //todo: later load privacy text from API and clean this up!
  const lastUpdated = "January 1, 2025" //todo: modified date should come from db
  const copyrightText = HtmlParse(db.data.copyright.text);
  const businessId = db.data.company.id

  //navigation
  const navigation = db.data.navigation

  useEffect(() => {
    fetch(privacyFileLocation)
    .then((r) => r.text())
    .then(privacy  => {
        setPrivacy(privacy)
    })  
  }, []);

  return (
    <>
      <Header
        links={navigation.menu}
        id="start"
        key="start"
        overlayImageUrl="/assets/images/style_header_curve.svg"
      />

      <PageView id="company-id" extendCss="background-color: #ffffff;">
        {HtmlParse(privacy.replaceAll("COMPANY_WEBSITE", companyWebsiteUrl).replaceAll("LAST_UPDATED", lastUpdated).replaceAll("COMPANY_NAME", companyWebsiteName).replaceAll("COMPANY_ADDRESS", companyAddress).replaceAll("COMPANY_LTD", companyName))}
        <Button path="/contact">Contact us</Button>
      </PageView>

      <Footer
        animate={true}
        links={navigation.footer}
        social={navigation.social}
        copyright={copyrightText}
        businessId={businessId}
      />
    </>
  );
};

export default PrivacyPage;
