import { useState } from "react";
import { ToggleWrapper } from "./Toggle.style";

export interface ToggleProps {
    id?: string;
    key?: string;
    uid: any;
    active?: false | boolean;
    disabled?: false | boolean;
    label?: string;
    onToggle: (key: string, toggle: boolean) => void;
    extendCss?: string;
    extendClass?: string;
}

const Toggle = ({...props}: ToggleProps) => {
    const [toggled, setToggled] = useState(props.active)

    const handleToggleCallback = () => {
        setToggled(!toggled)
        props.onToggle(props.uid, !toggled)
    }

    return (
        <ToggleWrapper>
            <label>
                {props.disabled ? (
                    <input key={props.key} type="checkbox" defaultChecked={toggled} disabled />
                    ) : (
                    <input key={props.key} type="checkbox" defaultChecked={toggled} onClick={() => handleToggleCallback()} />
                )}
                <span />
            </label>
        </ToggleWrapper>
    )
}

export default Toggle;