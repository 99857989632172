import { call, put } from "redux-saga/effects";
import axios from "axios";
import { setError, setSuccess } from "../features/contact/contact.api.slice";


const API_KEY = process.env.REACT_APP_WEB_API_KEY
const API_URL_BASE = process.env.REACT_APP_WEB_API_BASE_URL

const API_URL_CONTACT = API_URL_BASE + "contact"
const API_URL_HANDSHAKE = API_URL_BASE + "moi"

const KEY_CONTACT_RESPONSE_SUCCESS = "CONTACT_RESPONSE_SUCCESS"
const KEY_CONTACT_RESPONSE_FAILED = "CONTACT_RESPONSE_FAILED"


const handshakeBody = { 
  "apikey": API_KEY
};

const apiPostHandshake = (values) => {
  return axios.post(`${API_URL_HANDSHAKE}`, values);
};

const apiPostContact = (values, token) => {
  //console.log("apiPostContact()")
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  return axios.post(`${API_URL_CONTACT}`, values);
};

const requestContact = function* postContact(action) {
  //console.log("postContact(): " + JSON.stringify(action.payload))

  try {
    const handshakeResponse = yield call(apiPostHandshake, handshakeBody);
    const { token } = handshakeResponse.data.data

    const contactResponse = yield call(apiPostContact, action.payload, token);
    const { data } = contactResponse

    if (data === "OK") {
        yield put({ type: KEY_CONTACT_RESPONSE_SUCCESS, payload: {} });
        yield put(setSuccess())
        //console.log("ALL GOOD!")
    }

  } catch (e) {
    yield put({ type: KEY_CONTACT_RESPONSE_FAILED, payload: e.message });
    yield put(setError({ error: e.message }));
    //console.log("postContact() -> e: " + e);
  }
};

export default requestContact;