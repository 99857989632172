import { FieldErrors, FieldValues } from "react-hook-form";

/**
 * filters the errors object and returns the error of the input based on its name
 * @param errors 
 * @param name 
 * @returns 
 */
export function getInputError(errors: FieldErrors<FieldValues>, name: string): object {
  return Object.keys(errors)
    .filter(key => key.includes(name))
    .reduce((current, key: any) => {
      return Object.assign(current, { error: errors[key] })
    }, {})
};

/**
 * check whether form is valid or invalid
 * @param error 
 * @returns 
 */
export const isFormInvalid = (error: object): boolean => {
  return Object.keys(error).length > 0
};

/**
 * form validations
 */
export const nameValidation = {
  name: 'name',
  label: 'Names',
  type: 'text',
  id: 'name',
  placeholder: 'Enter your names',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    minLength: {
      value: 2,
      message: 'insufficient length',
    },
    maxLength: {
      value: 30,
      message: 'lengthy',
    },
  },
};

export const subjectValidation = {
  name: 'subject',
  label: 'Subject',
  type: 'text',
  id: 'subject',
  placeholder: 'Enter subject',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    minLength: {
      value: 5,
      message: 'insufficient length',
    },
  },
};

export const messageValidation = {
  name: 'message',
  label: 'Message',
  multiline: true,
  id: 'message',
  placeholder: 'Enter message',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    minLength: {
      value: 50,
      message: 'insufficient length',
    },
  },
};

export const descriptionValidation = {
  name: 'description',
  label: 'Description',
  multiline: true,
  id: 'description',
  placeholder: 'Enter description',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    maxLength: {
      value: 200,
      message: 'lengthy',
    },
  },
};

export const passwordValidation = {
  name: 'password',
  label: 'Password',
  type: 'password',
  id: 'password',
  placeholder: 'Enter your password',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    minLength: {
      value: 7,
      message: 'insufficient length',
    },
  },
};

export const phoneValidation = {
  name: 'phone',
  label: 'Phone',
  type: 'phone',
  id: 'phone',
  placeholder: 'Enter your phone',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    minLength: {
      value: 10,
      message: 'insufficient length',
    },
    pattern: {
      value:
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      message: 'invalid',
    },
  },
};

export const emailValidation = {
  name: 'email',
  label: 'E-mail address',
  type: 'email',
  id: 'email',
  placeholder: 'Enter your e-mail address',
  validation: {
    required: {
      value: true,
      message: 'required',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'invalid',
    },
  },
};
