import HtmlParse from 'htmr';

import { AlertCloseViewWrapper, AlertInnerViewWrapper, AlertViewWrapper } from "./AlertView.style";
import ImageView from "../ImageView/ImageView";

export enum AlertType {
    INFO,
    ALERT,
    ERROR,
    SUCCESS
}

export interface AlertViewProps {
    id?: string;
    hide?: boolean;
    content?: AlertContentProps;
    extendCss?: string;
}

export interface AlertContentProps {
    id: string;
    type?: AlertType;
    message: string;
    onClose?: () => void;
}

const AlertView = ({ ...props}: AlertViewProps) => {
    if (!props.content) return (<></>)

    const animationName = props.hide ? "animation-exit" : "animation-enter"
    const iconName = props.content.type === AlertType.INFO ? "info" : props.content.type === AlertType.ALERT ? "alert" : props.content.type === AlertType.SUCCESS ? "tick" : "error"

    return (
        <AlertViewWrapper className={animationName}>
            <AlertInnerViewWrapper>
                <ImageView src={`/icons/${iconName}.svg`} alt={iconName} width="64px" />
                <p>{HtmlParse(props.content.message)}</p>
            </AlertInnerViewWrapper>
            <AlertCloseViewWrapper>
                <ImageView src="/icons/close.svg" width="24px" alt="Close" onClick={props.content.onClose} />
            </AlertCloseViewWrapper>
        </AlertViewWrapper>
    )
};

export default AlertView;