import HtmlParse from 'htmr';

import db from "../../../../data/db.json";

import Header from '../../molecules/Header/Header';
import Footer from '../../molecules/Footer/Footer';
import SegmentView from '../../atoms/SegmentView/SegmentView';
import TitleView from '../../atoms/TitleView/TitleView';

const CookiesPage = () => {

    //navigation
    const menu = db.data.navigation.menu
    const social = db.data.navigation.social
    const footer = db.data.navigation.footer

    //footer
    const copyrightText = HtmlParse(db.data.copyright.text)
    const businessId = db.data.company.id

    return (
        <>
            <Header links={menu} id="start" key="start" overlayImageUrl='/assets/images/style_header_curve.svg' />
            
            <SegmentView id="cookies-id" extendCss='background-color: #ffffff; padding-top: 5%;' minHeight='auto'>
                    <TitleView color='#000000' title="Cookies settings" description="Sample description" />
            </SegmentView>

            <SegmentView minHeight='500px' extendCss='background-color: #1f262b;' />

            <Footer links={footer} social={social} copyright={copyrightText} businessId={businessId} />
        </>
    );
}

export default CookiesPage;