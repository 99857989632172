import { HashLink as Link } from "react-router-hash-link";
import { GoTopIconWrapper } from "./GoTopIcon.style";
import ImageView from "../../atoms/ImageView/ImageView";

export interface GoTopIconProps {
    id?: string;
    ref?: any;
    extendCss?: string;
}

const GoTopIcon = ({...props}: GoTopIconProps) => {
    return (
        <GoTopIconWrapper extendCss={props.extendCss} ref={props.ref}>
            <Link key="nav_go_to_top" to="#" scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })}>
                <ImageView src="/icons/arrow_up.svg" height="64px" extendCss="cursor: pointer; outline: none;" />
            </Link>
        </GoTopIconWrapper>
    );
};

export default GoTopIcon;