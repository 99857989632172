import styled from "styled-components";
import { InputViewProps } from "./InputView";

export const InputViewWrapper = styled.div.attrs((props: InputViewProps) => {
    return {
        extendCss: props.extendCss
    };
  })`
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0 32px 0;
    color: #ffffff;
    gap: 12px;

    label {
        font-size: calc(14px + 1vmin);
        color: #ffffff;
    }

    .input-error-view {
        display: flex;
        font-size: 18px;
        color: #ffffff;
        padding: 4px 6px;
        margin: 0;
        background-color: #ef233c;
        border-radius: 8px;
    }

    .input-error-view > span {
        padding-right: 6px;
        font-weight: 800;
    }

    textarea::-webkit-scrollbar {
        width: 2px;
        border-radius: 1em;
    }
    
    textarea::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    }
    
    textarea::-webkit-scrollbar-thumb {
      background-color: #2b2d42;
    }

    textarea {
        font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
        Arial, sans-serif;
        font-size: calc(18px + 1vmin);
        min-height: 10rem;
        max-height: 30rem;
        resize: vertical;
        outline: none;
        color: #fff;
        background: transparent;
        border: solid 2px #0266c350;
        border-radius: 4px;
        padding: 18px 24px;
    }

    textarea:focus {
        border: solid 2px #0266c3;
    }

    input {
        font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
        Arial, sans-serif;
        font-size: calc(18px + 1vmin);
        outline: none;
        color: #fff;
        background: transparent;
        border: solid 2px #0266c350;
        border-radius: 4px;
        padding: 18px 24px;
    }

    input:focus {
        border: solid 2px #0266c3;
    }

    ${(props) => props.extendCss};
`;

export const InputViewFieldWrapper = styled.div.attrs((props: InputViewProps) => {
    return {
        extendCss: props.extendCss
    };
  })`
    display: flex;
    justify-content: space-between;

    ${(props) => props.extendCss};
`;
