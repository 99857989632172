import { FormProvider } from 'react-hook-form'
import { emailValidation, messageValidation, nameValidation, phoneValidation, subjectValidation } from '../../../../utils/ValidationHelper';
import Button from '../../atoms/Button/Button';
import { InputView } from '../../atoms/InputView/InputView';
import { ContactFormButtonWrapper, ContactFormFieldsWrapper, ContactFormWrapper } from "./ContactForm.style";

export interface ContactFormProps {
    id?: string;
    contents: ContactContents;
    formMethods: any;
    onSubmitCallback: () => void;
    extendCss?: string;
}

export interface ContactContents {
    title: string;
    buttonText: string;
    buttonDisabled: boolean;
}

export interface ContactButtonProps {
    text: string;
}

const ContactForm = ({ ...props }: ContactFormProps) => {

    const formProps = {
        buttonDisabled: props.contents.buttonDisabled,
        buttonText: props.contents.buttonText,
        submit: props.onSubmitCallback
    }

    return (
        <ContactFormWrapper>
            <FormProvider {...props.formMethods}>
            <FormView { ...formProps } />
            </FormProvider>
        </ContactFormWrapper>
    );
};

const FormView = ({ ...props }) => {
    return (
        <form
        noValidate
        autoComplete='off'
        onSubmit={e => e.preventDefault()}>
            <ContactFormFieldsWrapper>
                <InputView {...nameValidation} />
                <InputView {...phoneValidation} />
                <InputView {...emailValidation} />
                <InputView {...subjectValidation} />
                <InputView {...messageValidation} />
            </ContactFormFieldsWrapper>
            <ContactFormButtonWrapper>
                <Button size='x-large' type='submit' onClick={!props.buttonDisabled && props.submit} disabled={props.buttonDisabled}>
                <span className="material-symbols-rounded">mail</span>
                {props.buttonText}
                </Button>
            </ContactFormButtonWrapper>
        </form>
    )
}

export default ContactForm;