import { SegmentViewColorWrapper, SegmentViewDecorationWrapper, SegmentViewGradientWrapper, SegmentViewInnerWrapper, SegmentViewWrapper } from "./SegmentView.style";

export interface SegmentViewProps {
    id?: string;
    logoUrl?: string;
    header?: string;
    title?: string;
    description?: string;
    minHeight?: "720px" | string;
    backgroundImageUrl?: string;
    backgroundGradientBottomWeight?: string;
    backgroundGradientColors?: GradientColorsProps;
    backgroundSize?: "cover" | "auto" | string;
    overlayImageUrl?: string;
    children?: any;
    extendClass?: string;
    extendCss?: string;
    extendInnerCss?: string;
}

export interface GradientColorsProps {
    rotationDegree?: string;
    startColor: string;
    startPercentage: string;
    endColor: string;
    endPercentage: string;
}

const SegmentView = ({ backgroundGradientBottomWeight = "0.3", ...props }: SegmentViewProps) => {

    const innerWrapper = <>
    <SegmentViewDecorationWrapper className={props.extendClass} backgroundSize={props.backgroundSize} overlayImageUrl={props.overlayImageUrl}/>
    <SegmentViewInnerWrapper extendInnerCss={props.extendInnerCss}>
    {props.header && (<h1 className="header">{props.header}</h1>)}
    {props.title && (<h1>{props.title}<br /></h1>)}
    {props.description && (<h3>{props.description}</h3>)}
    {props.children}
    </SegmentViewInnerWrapper></>

    const segmentViewBackgroundImage = <SegmentViewWrapper id={props.id} key={props.id} minHeight={props.minHeight} backgroundImageUrl={props.backgroundImageUrl} backgroundGradientBottomWeight={backgroundGradientBottomWeight} extendCss={props.extendCss}>
        {innerWrapper}
        </SegmentViewWrapper>

    const segmentViewBackgroundColor = <SegmentViewColorWrapper id={props.id} key={props.id} minHeight={props.minHeight} extendCss={props.extendCss}>
    {innerWrapper}
    </SegmentViewColorWrapper>

    const segmentViewBackgroundGradientColors = <SegmentViewGradientWrapper id={props.id} key={props.id} minHeight={props.minHeight} backgroundGradientColors={props.backgroundGradientColors} extendCss={props.extendCss}>
    {innerWrapper}
    </SegmentViewGradientWrapper>

    return props.backgroundImageUrl ? segmentViewBackgroundImage : props.backgroundGradientColors ? segmentViewBackgroundGradientColors : segmentViewBackgroundColor
};

export default SegmentView;