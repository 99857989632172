import createSagaMiddleware from "redux-saga";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

import contactReducer from "../features/contact/contact.api.slice";
import cookiesReducer from "../features/cookies/cookies.api.slice";

import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
    contact: contactReducer,
    cookies: cookiesReducer
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
      return [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];
    },
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;