import { createSlice } from "@reduxjs/toolkit";

export interface ApiMessageProps {
    message?: string;
    code?: string;
}

export interface ContactDataProps {
    name?: string;
    email?: string;
    phone?: string;
    subject?: string;
    message?: string;
}

export interface ContactDataState {
    data: ContactDataProps;
    error: string,
    success: boolean
}

const initialState: ContactDataState = {
    data: {},
    error: "",
    success: false
};

const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        loadContact(state) {
            return state
        },
        setError(state, action) {
            return { 
                ...state,
                error: action.payload.error,
                success: false
            };
        },
        setSuccess(state) {
            return { 
                ...state,
                error: "",
                success: true
            };
        },
        setContact(state, action)  {
            const data = action.payload;

            return { 
                ...state,
                data: data
            };
        },
        resetContact(state) {
            return { 
                ...state,
                data: {},
                error: "",
                success: false
            };
        }    
    }
});

export const { loadContact, setContact, setError, setSuccess, resetContact } = contactSlice.actions;
export default contactSlice.reducer;