import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { store } from "./store";
import { Provider } from 'react-redux';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename='/'>
      <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
