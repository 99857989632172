import React from "react";
import { Route, Routes } from "react-router-dom";
import { getRoutes } from "./navigation/NavigationMap";
import { Fragment, useState } from "react";
import ScrollToTop from "./utils/ScrollToTop";
import "./App.css";
import Loader from "./components/ui/atoms/Loader/Loader";
import { isCookiesConsentGiven } from "./utils/CookiesHelper";
import Alert, { AlertProps } from "./components/features/Alert/Alert";

// settings
interface SettingsPanelProps {
  isLoading: boolean,
  setLoading?: (visible: boolean) => void;
  isAlert?: AlertProps;
  setAlert?: (content?: AlertProps) => void;
  displaySimpleCookiesPanel: boolean,
  displayExtendedCookiesPanel: boolean,
  setDisplaySimpleCookiesPanel?: (visible: boolean) => void
  setDisplayExtendedCookiesPanel?: (visible: boolean) => void
}

const settingsPanelProps: SettingsPanelProps = {
  isLoading: false,
  isAlert: undefined,
  displayExtendedCookiesPanel: false,
  displaySimpleCookiesPanel: false
}

export const SettingsContext = React.createContext<SettingsPanelProps>(settingsPanelProps);

function App() {

  const [isLoading, setLoading] = useState(false);
  const [displaySimpleCookiesPanel, setDisplaySimpleCookiesPanel] = useState(!isCookiesConsentGiven());
  const [displayExtendedCookiesPanel, setDisplayExtendedCookiesPanel] = useState(false);
  const [isAlert, setAlert] = useState<AlertProps>();

  // cookies -> simple
  const onDisplaySimpleCookiePanel = (visible: boolean) => {
    setDisplaySimpleCookiesPanel(visible)
  }

  // cookies -> extended
  const onDisplayExtendedCookiePanel = (visible: boolean) => {
    setDisplaySimpleCookiesPanel(false)
    setDisplayExtendedCookiesPanel(visible)
  }

  // loading
  const onDisplayLoading = (visible: boolean) => {
    setLoading(visible)
  }

  // alert
  const onDisplayAlert = (content?: AlertProps) => {
    setAlert(content)
  }

  return (
    <div className="App">
      {isLoading && <Loader />}
      {isAlert && <Alert {...isAlert} />}
      <SettingsContext.Provider
        value={{
          isLoading: isLoading,
          setLoading: onDisplayLoading,
          isAlert: isAlert,
          setAlert: onDisplayAlert, 
          displaySimpleCookiesPanel: displaySimpleCookiesPanel,
          setDisplaySimpleCookiesPanel: onDisplaySimpleCookiePanel,
          displayExtendedCookiesPanel: displayExtendedCookiesPanel,
          setDisplayExtendedCookiesPanel: onDisplayExtendedCookiePanel,
        }}
      >
      <ScrollToTop />
        <Routes>
          {getRoutes().map((item, key) => (
            <Fragment key={key}>
              <Route key={key} path={item.path} element={item.component} />
              {item.routes?.map((subItem, subKey) => (
                <Route
                  key={"sub_route_" + subKey}
                  path={subItem.path}
                  element={subItem.component}
                />
              ))}
            </Fragment>
          ))}
        </Routes>
      </SettingsContext.Provider>
    </div>
  );
}

export default App;
