import HtmlParse from 'htmr';

import db from "../../../../data/db.json";

import Header from '../../molecules/Header/Header';
import Footer from '../../molecules/Footer/Footer';
import PageView from '../../atoms/PageView/PageView';
import { useEffect, useState } from 'react';

const TermsPage = () => {
    const [terms, setTerms] = useState("")

    //navigation
    const navigation = db.data.navigation

    //footer
    const companyName = db.data.company.name;
    const companyAddress = db.data.company.address;
    const companyWebsiteName = db.data.company.website.name;
    const companyWebsiteUrl = db.data.company.website.url;
  
    const termsFileLocation = "/data/terms.txt" //todo: later load privacy text from API and clean this up!
    const copyrightText = HtmlParse(db.data.copyright.text);
    const businessId = db.data.company.id

    useEffect(() => {
        fetch(termsFileLocation)
        .then((r) => r.text())
        .then(privacy  => {
            setTerms(privacy)
        })  
      }, []);
    
    return (
        <>
            <Header links={navigation.menu} id="start" key="start" overlayImageUrl='/assets/images/style_header_curve.svg' />
      
            <PageView id="company-id" extendCss="background-color: #ffffff;">
                {HtmlParse(terms.replaceAll("COMPANY_WEBSITE", companyWebsiteUrl).replaceAll("COMPANY_NAME", companyWebsiteName).replaceAll("COMPANY_ADDRESS", companyAddress).replaceAll("COMPANY_LTD", companyName))}
            </PageView>

            <Footer links={navigation.footer} social={navigation.social} copyright={copyrightText} businessId={businessId} />
        </>
    );
}

export default TermsPage;