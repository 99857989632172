import styled from "styled-components";
import { ToggleProps } from "./Toggle";

export const ToggleWrapper = styled.div.attrs((props: ToggleProps) => {
  return {
    extendCss: props.extendCss,
  };
})`
  display: flex;
  padding: 0 24px;
  justify-items: center;
  flex-direction: column;

  label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #343a40;
    transition: 0.3s;
    border-radius: 30px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + span {
    background-color: #0266c3;
  }

  input:checked + span:before {
    transform: translateX(29px);
  }

  input:checked:disabled + span {
    opacity: 0.3;
    cursor: default;
  }

  input:disabled + span {
    opacity: 0.3;
    cursor: default;
  }


  ${(props) => props.extendCss};
`;
