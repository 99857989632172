import styled from "styled-components";
import { SegmentViewProps } from "./SegmentView";

export const SegmentViewWrapper = styled.div.attrs((props: SegmentViewProps) => {
    return {
        minHeight: props.minHeight,
        backgroundImageUrl: props.backgroundImageUrl,
        backgroundGradientBottomWeight: props.backgroundGradientBottomWeight,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      position: relative;
      align-items: center;
      justify-items: center;
      justify-content: center;
      width: 100%;
      min-height: ${(props) => props.minHeight};

      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, ${(props) => props.backgroundGradientBottomWeight})), url(${(props) => props.backgroundImageUrl});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      -webkit-background-size: cover;

      ${(props) => props.extendCss};
  `;

  export const SegmentViewDecorationWrapper = styled.div.attrs((props: SegmentViewProps) => {
    return {
        overlayImageUrl: props.overlayImageUrl,
        backgroundSize: props.backgroundSize
    };
  })`
    position: absolute;
    z-index: 1;
    margin-bottom: -20px;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.overlayImageUrl});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: ${(props) => props.backgroundSize};
    -moz-background-size: ${(props) => props.backgroundSize};
    -o-background-size: ${(props) => props.backgroundSize};
    -webkit-background-size: ${(props) => props.backgroundSize};
  `;

  export const SegmentViewColorWrapper = styled.div.attrs((props: SegmentViewProps) => {
    return {
        minHeight: props.minHeight,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      justify-items: center;
      justify-content: center;
      width: 100%;
      min-height: ${(props) => props.minHeight};

      @media (min-width: 0px) and (max-width: 640px) {
          width: 100%;
          flex-wrap: wrap;
      }

    ${(props) => props.extendCss};
  `;

  export const SegmentViewGradientWrapper = styled.div.attrs((props: SegmentViewProps) => {
    return {
        backgroundGradientColors: props.backgroundGradientColors,
        minHeight: props.minHeight,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      justify-items: center;
      justify-content: center;
      width: 100%;
      min-height: ${(props) => props.minHeight};

      background: linear-gradient(
        ${(props) => props.backgroundGradientColors?.rotationDegree ? props.backgroundGradientColors?.rotationDegree : "180" }deg,
        ${(props) => props.backgroundGradientColors?.startColor ? props.backgroundGradientColors?.startColor : "rgba(31,38,43,1)" } 
        ${(props) => props.backgroundGradientColors?.startColor ? props.backgroundGradientColors?.startPercentage : "0%" }, 
        ${(props) => props.backgroundGradientColors?.endColor ? props.backgroundGradientColors?.endColor : "rgba(22,29,34,1)" } 
        ${(props) => props.backgroundGradientColors?.endPercentage ? props.backgroundGradientColors?.endPercentage : "100%" }
        );

      @media (min-width: 0px) and (max-width: 640px) {
          width: 100%;
          flex-wrap: wrap;
      }

    ${(props) => props.extendCss};
  `;

    export const SegmentViewInnerWrapper = styled.div.attrs((props: SegmentViewProps) => {
        return {
          extendInnerCss: props.extendInnerCss,
        };
      })`
        position: relative;
        display: flex;
        width: 80%;
        flex-wrap: wrap;
        justify-content: center;
        justify-items: center;
        gap: 32px;
        padding: 50px 0 150px 0;

        img.decoration {
          position: absolute;
          bottom: 0;
          width: 125%;
        }

        @media (min-width: 0px) and (max-width: 960px) {
            gap: 4px;
            justify-items: center;
            justify-content: center;
        }

      ${(props) => props.extendInnerCss};
    `;
