import styled from "styled-components";
import { ContactFormProps } from "./ContactForm";

export const ContactFormWrapper = styled.div.attrs((props: ContactFormProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      width: 80%;
      min-height: 640px;
      justify-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 5;

      @media (min-width: 0px) and (max-width: 960px) {
        width: 100%;
      }

      ${(props) => props.extendCss};
  `;

export const ContactFormFieldsWrapper = styled.div.attrs((props: ContactFormProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      width: 100%;
      justify-items: center;
      justify-content: center;
      flex-direction: column;

      ${(props) => props.extendCss};
  `;

  export const ContactFormButtonWrapper = styled.div.attrs((props: ContactFormProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      justify-items: center;
      justify-content: center;
      flex-direction: row;
      margin-top: 32px;

      span {
        font-size: calc(24px + 1vmin);
        margin-right: 12px;
      }

      ${(props) => props.extendCss};
  `;
