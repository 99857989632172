import { LoaderParentWrapper, LoaderPulseWrapper, LoaderSmallRingWrapper, LoaderLargeRingWrapper } from "./Loader.style";

export interface LoaderProps {
    foregroundColor?: string;
    backgroundColor?: string;
    extendCss?: string;
}

const Loader = ({ ...props }: LoaderProps) => {
    return (
        <LoaderParentWrapper>
            <LoaderLargeRingWrapper>
                <LoaderSmallRingWrapper>
                    <LoaderPulseWrapper/>
                    <LoaderPulseWrapper/>
                </LoaderSmallRingWrapper>
            </LoaderLargeRingWrapper>
        </LoaderParentWrapper>
    )
}

export default Loader;