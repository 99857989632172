import { createSlice } from "@reduxjs/toolkit";
import { AcceptedCookies } from "../../components/ui/molecules/CookiesView/CookiesView";

export interface CookieConsentState {
    data?: AcceptedCookies;
    timestamp?: string;
}

const initialState: CookieConsentState = {
    data: {
        necessaryCookies: false,
        performanceCookies: false,
        functionalCookies: false
    },
    timestamp: ""
};

const contactSlice = createSlice({
    name: "cookies",
    initialState,
    reducers: {
        getCookieConsent(state) {
            return state
        },
        setCookieConsent(state, action)  {
            const timestamp = new Date().toISOString()

            return { 
                ...state,
                data: action.payload,
                timestamp: timestamp
            };
        },
        resetCookieConsent(state) {
            return { 
                ...state,
                data: initialState.data,
                timestamp: ""
            };
        }    
    }
});

export const { getCookieConsent, setCookieConsent, resetCookieConsent } = contactSlice.actions;
export default contactSlice.reducer;