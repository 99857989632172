import { ReactElement, JSXElementConstructor } from "react";
import { TitleViewInnerWrapper, TitleViewWrapper } from "./TitleView.style";
import ImageView from "../ImageView/ImageView";

export interface TitleViewProps {
    id?: string;
    color?: string;
    textAlign?: "left" | "center" | "right" | string;
    title?: string;
    subtitle?: string;
    titleSize?: "h1" | "h2" | "h3" | string;
    iconFilename?: string;
    capitalize?: true | boolean;
    description?: string | ReactElement<any, string | JSXElementConstructor<any>>[];
    extendCss?: string;
}

const TitleView = ({ ...props }: TitleViewProps) => {
    return props.iconFilename ? TitlesWithIcon(props) : TitlesWithDescription(props)
}

const TitlesWithDescription = ({...props }: TitleViewProps) => {
    return (
        <TitleViewWrapper id={props.id} key={props.id} extendCss={props.extendCss}>
            <TitleViewInnerWrapper color={props.color} textAlign={props.textAlign} capitalize={props.capitalize} extendCss={props.extendCss}>
            {props.title && props.titleSize === "h1" ? <h1>{props.title}</h1> : props.titleSize === "h2" ?  <h2>{props.title}</h2> : props.titleSize === "h3" ?  <h3>{props.title}</h3> : <h1>{props.title}</h1> }
            {props.subtitle && <h4>{props.subtitle}</h4> }
            {props.description && <p>{props.description}</p> }
            </TitleViewInnerWrapper>
        </TitleViewWrapper>
    )
}

const TitlesWithIcon = ({...props}: TitleViewProps) => {
    return (
        <TitleViewWrapper id={props.id} key={props.id} extendCss={props.extendCss}>
            <TitleViewInnerWrapper color={props.color} textAlign={props.textAlign} capitalize={props.capitalize} extendCss={props.extendCss}>
                {props.iconFilename && <ImageView src={props.iconFilename} alt={props.title} height='128px' extendCss="margin-bottom: 32px;" />}
                {props.title && props.titleSize === "h1" ? <h1>{props.title}</h1> : props.titleSize === "h2" ?  <h2>{props.title}</h2> : props.titleSize === "h3" ?  <h3>{props.title}</h3> : <h1>{props.title}</h1> }
                {props.subtitle && <h4>{props.subtitle}</h4> }
            </TitleViewInnerWrapper>
        </TitleViewWrapper>
    )
}

export default TitleView;