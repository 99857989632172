import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import ContactForm, { ContactFormProps } from "../../ui/molecules/ContactForm/ContactForm";
import { SettingsContext } from "../../../App";

import {
    resetContact,
    setContact,
} from "../../../features/contact/contact.api.slice"
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import timeout from "../../../utils/TimeHelper";
import { AlertType } from "../../ui/atoms/AlertView/AlertView";
import { AlertProps } from "../Alert/Alert";
import generateUuid from "../../../utils/NumberHelper";

const Contact = () => {
    const { isLoading, setLoading } = useContext(SettingsContext);
    const { setAlert } = useContext(SettingsContext);

    const delayTimeoutInSeconds = 2
    const methods = useForm()
    const dispatch = useAppDispatch();
    
    const contactError: string = useAppSelector(
        (state) => state.contact.error
    );
    
    const isSuccess: boolean = useAppSelector(
        (state) => state.contact.success
    );

    const isError = contactError.length > 0

    const onSubmitCallback = methods.handleSubmit( async data => {
        setLoading?.(true)
        setAlert?.(undefined)
        dispatch(setContact(data))

        await timeout(5500);
        setLoading?.(false)
    });

    const errorAlertContent: AlertProps = {
        autoHide: true,
        content: {
            id: generateUuid(),
            message: contactError,
            type: AlertType.ERROR,
            onClose: () => {
                setAlert?.(undefined)
            }
        }
    }

    const successAlertContent: AlertProps = {
        autoHide: true,
        content: {
            id: generateUuid(),
            message: "Your message has been sent.",
            type: AlertType.SUCCESS,
            onClose: () => {
                setAlert?.(undefined)
            }
        }
    }

    useEffect(
        () => {
          const onShowMessage = () => {

            if (isSuccess) {
                //console.log("Pushing new success: " + isSuccess)
                setAlert?.(successAlertContent)
                methods.reset()
            }
        
            if (isError) {
                //console.log("Pushing new error: " + contactError)
                setAlert?.(errorAlertContent)
            }

            dispatch(resetContact())
          }

          let timeoutId = setTimeout(() => { onShowMessage()}, delayTimeoutInSeconds * 1000);
          return () => {
            clearTimeout(timeoutId);
        };
        },
        [isError, isSuccess]
    );

    const contactProps : ContactFormProps = {
        contents: {
            title: "Contact",
            buttonText: "Send",
            buttonDisabled: isLoading
        },
        formMethods: methods,
        onSubmitCallback: onSubmitCallback
    }

    return (<ContactForm {...contactProps} />);
};

export default Contact;