/**
 * Helper function to add timedelay
 * 
 * usage: await timeout(2000); //2 sec delay
 * 
 * @param {ms} delay 
 * @returns 
 */
export default function timeout(delay = 2000) {
    return new Promise( res => setTimeout(res, delay) );
}