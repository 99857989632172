import ImageView from "../ImageView/ImageView";
import { CardViewInnerWrapper, CardViewWrapper } from "./CardView.style";

export interface CardViewProps {
    id?: string;
    iconUrl?: string;
    iconSize?: string;
    imageUrl?: string;
    title?: string;
    titleColor?: string;
    description?: string;
    descriptionColor?: string;
    hideShadow?: boolean;
    minHeight?: string;
    backgroundColor?: string;
    hoverColor?: string;
    onClick?: (e: MouseEvent | any) => void;
    children?: any;
    extendCss?: string;
}

const CardView = ({hideShadow = false, backgroundColor = '#fff', minHeight = 'auto', iconSize = '32px', ...props }: CardViewProps) => {
    return (
        <CardViewWrapper data-id={props.id} backgroundColor={backgroundColor} hoverColor={props.hoverColor} minHeight={minHeight} hideShadow={hideShadow} onClick={props.onClick}>
            <CardViewInnerWrapper titleColor={props.titleColor} descriptionColor={props.descriptionColor}>
                {props.iconUrl && <ImageView src={props.iconUrl} width={iconSize} extendCss="margin-bottom: 12px;" />}
                <h4>{props.title}</h4>
                {props.imageUrl && <ImageView src={props.imageUrl} />}
                {props.description && <p>{props.description}</p>}
            </CardViewInnerWrapper>
        </CardViewWrapper>
    );
};

export default CardView;