import { useContext } from "react";
import { SettingsContext } from "../../../App";
import CookiesView, {
  AcceptedCookies,
  CookieViewSettingsItemProps,
  CookiesButtonActions,
  CookiesViewProps,
  CookiesViewSettingsProps,
} from "../../ui/molecules/CookiesView/CookiesView";
import { useAppDispatch } from "../../../store/hooks";
import { setCookieConsent } from "../../../features/cookies/cookies.api.slice";
import {
  clearCookieConsentData,
  storeCookies,
} from "../../../utils/CookiesHelper";
import timeout from "../../../utils/TimeHelper";

const CookiesConsent = () => {
  const dispatch = useAppDispatch();

  const { setLoading } = useContext(SettingsContext);
  const { displaySimpleCookiesPanel, setDisplaySimpleCookiesPanel } =
    useContext(SettingsContext);
  const { displayExtendedCookiesPanel, setDisplayExtendedCookiesPanel } =
    useContext(SettingsContext);

  const cookieTitle = "Before you start...";
  const cookieDescription =
    "We use first- and third-party cookies including other tracking technologies from third party publishers to give you the full functionality of our website, to customize your user experience, perform analytics and deliver personalized advertising on our websites, apps and newsletters across internet and via social media platforms. For that purpose, we collect information about user, browsing patterns and device.\n\n" +
    'By clicking "Accept All Cookies", you accept this, and agree that we share this information with third parties, such as our advertising partners. If you prefer, you can choose to continue with "Only Required Cookies". But keep in mind that blocking some types of cookies may impact how we can deliver tailored content that you might like.\n\n' +
    'For more information and to customize your options, click on "Cookie settings". If you want to learn more about cookies and why we use them, visit our Cookie Policy page at any time.';

  const cookieSettingsItems: Array<CookieViewSettingsItemProps> = [
    {
      key: "necessaryCookies",
      title: "Strictly Necessary Cookies",
      description:
        "These cookies are necessary to maintain our services and cannot be switched off. They are usually only set in response to actions made by you, such as creating an account or saving a wish-list for later. You can set your browser to block or alert you about these cookies, but that can make some parts of the site not work. These cookies do not store any personally identifiable information.",
      active: true,
      disabled: true,
    },
    {
      key: "performanceCookies",
      title: "Performance Cookies",
      description:
        "These cookies allow us to count visits and traffic so we can collect insights like which pages are the most popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore, anonymous. If you do not allow these cookies, we will not be able to provide you with a tailored experience.",
    },
    {
      key: "functionalCookies",
      title: "Functional Cookies",
      description:
        "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies, then some or all of these services may not function properly.",
    },
  ];

  const cookieSettings: CookiesViewSettingsProps = {
    header: "Manage consent preferences",
    items: cookieSettingsItems,
  };

  const handleCookiesSelection = async (
    acceptedCookies: AcceptedCookies,
    action: CookiesButtonActions
  ) => {
    if (action) {
      setLoading?.(true);

      setDisplayExtendedCookiesPanel?.(false);
      setDisplaySimpleCookiesPanel?.(false);

      dispatch(setCookieConsent(acceptedCookies));

      clearCookieConsentData();

      Object.entries(acceptedCookies).forEach((cookie) => {
        const cookieName = cookie[0];
        const cookieValue = cookie[1];

        storeCookies(cookieName.toString(), cookieValue);
      });

      await timeout();
      setLoading?.(false);
    }
  };

  const handleCookiesSettings = (displayCookieSettings: boolean) => {
    if (displayCookieSettings) {
      setDisplayExtendedCookiesPanel && setDisplayExtendedCookiesPanel(true);
      //console.log("Display cookie settings");
    }
  };

  //cookies simple panel
  const cookiesSimpleProps: CookiesViewProps = {
    title: cookieTitle,
    description: cookieDescription,
    onSelectCookies: handleCookiesSelection,
    onShowCookiesSettings: handleCookiesSettings,
  };

  //cookies extended panel
  const cookiesExtendedProps: CookiesViewProps = {
    title: cookieTitle,
    description: cookieDescription,
    cookieSettings: cookieSettings,
    displayExtendedCookieSettings: displayExtendedCookiesPanel,
    onSelectCookies: handleCookiesSelection,
    onShowCookiesSettings: handleCookiesSettings,
  };

  return displaySimpleCookiesPanel ? (
    <CookiesView {...cookiesSimpleProps} />
  ) : displayExtendedCookiesPanel ? (
    <CookiesView {...cookiesExtendedProps} />
  ) : (
    <></>
  );
};

export default CookiesConsent;
