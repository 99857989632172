import styled from "styled-components";
import { TitleViewProps } from "./TitleView";

export const TitleViewWrapper = styled.div.attrs((props: TitleViewProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 50px 0;

      ${(props) => props.extendCss};
  `;

  export const TitleViewInnerWrapper = styled.div.attrs((props: TitleViewProps) => {
    return {
        capitalize: props.capitalize,
        color: props.color,
        textAlign: props.textAlign,
        extendCss: props.extendCss,
    };
  })`
    display: flex;
    flex-direction: column;
    text-wrap: wrap;
    white-space: pre-line;

    @media (min-width: 1280px) {
        padding: 0 96px;
    }

    h1 {
        text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
        color: ${(props) => (props.color ? props.color : "#ffffff")};
        text-transform: ${(props) => (props.capitalize ? "uppercase" : "normal")};
    }

    h2 {
        text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
        color: ${(props) => (props.color ? props.color : "#ffffff")};
        text-transform: ${(props) => (props.capitalize ? "uppercase" : "normal")};
    }

    h3 {
        text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
        color: ${(props) => (props.color ? props.color : "#ffffff")};
        text-transform: ${(props) => (props.capitalize ? "uppercase" : "normal")};
    }

    h4 {
        text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
        color: ${(props) => (props.color ? props.color : "#ffffff")};
    }

    p {
        text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
        color: ${(props) => (props.color ? props.color : "#ffffff")};
    }

    ${(props) => props.extendCss};
`;
