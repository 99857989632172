import styled from "styled-components";
import { ButtonProps } from "./Button";

export const ButtonWrapper = styled.button.attrs((props: ButtonProps) => {
  return {
    size: props.size,
    width: props.width,
    height: props.height,
    extendCss: props.extendCss,
  };
})`
  display: flex;
  font-family: "Quicksand", "Nunito Sans", "Sofia", "Helvetica Neue", Helvetica,
  Arial, sans-serif;
  font-size: calc(8px + 2vmin);
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) =>
    props.height
      ? props.height
      : props.size === "x-large"
      ? "56px"
      : props.size === "large"
      ? "50px"
      : props.size === "medium"
      ? "40px"
      : "auto"};
  padding: ${(props) =>
    props.height
      ? props.height
      : props.size === "x-large"
      ? "40px 56px"
      : props.size === "large"
      ? "24px 36px"
      : props.size === "medium"
      ? "10px 24px"
      : "10px 24px"};
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  color: #fff;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  align-items: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.dark {
    color: #ffffff;
    border: #0b0a0a solid 2px;
    background-color: #0b0a0a;
  }
  &.dark:active {
    color: #fff;
    background-color: #343a40;
  }
  &.theme {
    background-color: var(--primary-color, #0266c3);
  }
  &.theme:active {
    color: #fff;
    background-color: #023e8a;
  }
  &.light {
    color: #0b0a0a;
    border: #0b0a0a solid 2px;
    background-color: #00000000;
  }
  &.light:active {
    color: #ffffff;
    background-color: #343a40;
  }
  :focus {
    outline: none;
  }
  :disabled,
  :disabled:active,
  [disabled] {
    border: 0;
    background-color: #e3e3e3;
    color: #969696;
    cursor: default;
  }

  ${(props) => props.extendCss}
`;