import styled from "styled-components";
import { PageViewProps } from "./PageView";

export const PageViewWrapper = styled.div.attrs((props: PageViewProps) => {
  return {
    extendCss: props.extendCss,
  };
})`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  gap: 32px;

  ${(props) => props.extendCss};
`;

export const PageInnerViewWrapper = styled.div.attrs((props: PageViewProps) => {
  return {
    extendInnerCss: props.extendInnerCss,
  };
})`
  display: flex-inline;
  flex-wrap: wrap;
  text-align: left;
  width: 80%;
  gap: 32px;
  padding: 100px 0 200px 0;
  font-size: calc(10px + 2vmin);

  h1 {
    text-align: left;
    padding: 24px 0 0 0;
  }

  h2 {
    text-align: left;
    padding: 12px 0 0 0;
  }

  h3 {
    text-align: left;
    padding: 12px 0 0 0;
  }

  p {
    text-align: left;
  }

  ul {
    justify-content: flex-start;
    justify-items: flex-start;
    flex-direction: column;
    list-style-type: none;
    gap: 12px;
  }

  li {
    display: flex-inline;
    flex-wrap: wrap;
    list-style: none;
    padding: 4px 24px;
    font-size: calc(12px + 2vmin);

    text-align: left;

    :before {
      content: "•";
      padding-right: 24px;
    }
  }

  ${(props) => props.extendInnerCss};
`;
