import { useState } from "react";
import Button from "../../atoms/Button/Button";
import Toggle from "../../atoms/Toggle/Toggle";
import { CookiesViewButtonsWrapper, CookiesViewSettingsButtonsWrapper, CookiesViewSettingsOptionItemWrapper, CookiesViewSettingsOptionTextWrapper, CookiesViewSettingsOptionsWrapper, CookiesViewSettingsTextWrapper, CookiesViewSettingsWrapper, CookiesViewTextWrapper, CookiesViewTitleWrapper, CookiesViewWrapper } from "./CookiesView.style";

export interface CookiesViewProps {
    id?: string;
    title: string;
    description: string;
    displayExtendedCookieSettings?: boolean;
    cookieSettings?: CookiesViewSettingsProps;
    onSelectCookies: (acceptedCookies: AcceptedCookies, option: CookiesButtonActions) => void;
    onShowCookiesSettings: (display: boolean) => void | Promise<void>;
    extendCss?: string;
}

export interface CookiesViewSettingsProps {
    header: string;
    items: Array<CookieViewSettingsItemProps>;
}

export interface CookieViewSettingsItemProps {
    id?: string;
    key: keyof AcceptedCookies;
    title: string;
    description: string;
    active?: boolean;
    disabled?: boolean;
}

export type AcceptedCookies = {
    necessaryCookies: boolean;
    performanceCookies: boolean;
    functionalCookies: boolean;
}

export enum CookiesButtonActions {
    ACCEPT_ALL = 1,
    ACCEPT_NECESSARY_ONLY = 2,
    ACCEPT_MY_CHOICES = 3
}

const CookiesView = ({...props}: CookiesViewProps) => {
    return props.displayExtendedCookieSettings && props.cookieSettings ? CookieViewSettings(props) : CookieViewSimple(props)
}

const CookieViewSimple = ({...props}: CookiesViewProps) => {
    const acceptAllCookies: AcceptedCookies = {
        necessaryCookies: true,
        performanceCookies: true,
        functionalCookies: true
    }

    const acceptNecessaryCookies: AcceptedCookies = {
        necessaryCookies: true,
        performanceCookies: false,
        functionalCookies: false
    }

    return (
        <CookiesViewWrapper>
            <CookiesViewTitleWrapper>
                <h4>{props.title}</h4>
            </CookiesViewTitleWrapper>
            <CookiesViewTextWrapper>
                <p key="cookie_description">{props.description}</p>
            </CookiesViewTextWrapper>
            <CookiesViewButtonsWrapper>
                <Button mode="dark" onClick={() => props.onSelectCookies(acceptAllCookies, CookiesButtonActions.ACCEPT_ALL)} extendCss="width: 100%; margin: 3px 0;">Accept all cookies</Button>
                <Button mode="dark" onClick={() => props.onSelectCookies(acceptNecessaryCookies, CookiesButtonActions.ACCEPT_NECESSARY_ONLY)} extendCss="width: 100%; margin: 3px 0;">Only necessary cookies</Button>
                <Button mode="light" onClick={() => props.onShowCookiesSettings(true)} extendCss="width: 100%; margin: 3px 0;">Cookies settings</Button>
            </CookiesViewButtonsWrapper>
        </CookiesViewWrapper>
    )
}

const CookieViewSettings = ({...props}: CookiesViewProps) => {
    const [acceptSelectedCookies, setAcceptSelectedCookies] = useState<AcceptedCookies>({
        necessaryCookies: true,
        performanceCookies: false,
        functionalCookies: false
    })

    const acceptAllCookies: AcceptedCookies = {
        necessaryCookies: true,
        performanceCookies: true,
        functionalCookies: true
    }

    const acceptNecessaryCookies: AcceptedCookies = {
        necessaryCookies: true,
        performanceCookies: false,
        functionalCookies: false
    }

    const handleToggle = (uid: any, toggle: boolean) => {
        const cookieKey = uid as keyof AcceptedCookies
        setAcceptSelectedCookies({
            ...acceptSelectedCookies,
            [cookieKey]: toggle
        })
    }

    return (
        <CookiesViewSettingsWrapper>
            <CookiesViewTitleWrapper>
                <h4>{props.title}</h4>
            </CookiesViewTitleWrapper>
            <CookiesViewSettingsTextWrapper>
                <p key={"cookie_description"}>{props.description}</p>

                <CookiesViewSettingsOptionsWrapper>
                    <h4>{props.cookieSettings?.header}</h4>

                    {props.cookieSettings!!.items.map((option, index) => (
                    <CookiesViewSettingsOptionItemWrapper key={"cookie_setting_option_" + index}>
                        <CookiesViewSettingsOptionTextWrapper>
                            <h5 key={"cookie_option_title_" + index}>{option.title}</h5>
                            <Toggle active={option.active} key={"cookie_setting_option_" + index} uid={option.key} disabled={option.disabled} onToggle={handleToggle} />
                        </CookiesViewSettingsOptionTextWrapper>
                        <p key={"cookie_option_description_" + index}>{option.description}</p>
                    </CookiesViewSettingsOptionItemWrapper>
                    ))}

                </CookiesViewSettingsOptionsWrapper>

            </CookiesViewSettingsTextWrapper>
            <CookiesViewSettingsButtonsWrapper>
                <Button mode="dark" onClick={() => props.onSelectCookies(acceptSelectedCookies, CookiesButtonActions.ACCEPT_MY_CHOICES)} extendCss="width: 100%; margin: 3px 0;">Confirm my choices</Button>
                <Button mode="dark" onClick={() => props.onSelectCookies(acceptNecessaryCookies, CookiesButtonActions.ACCEPT_NECESSARY_ONLY)} extendCss="width: 100%; margin: 3px 0;">Only necessary cookies</Button>
                <Button mode="dark" onClick={() => props.onSelectCookies(acceptAllCookies, CookiesButtonActions.ACCEPT_ALL)} extendCss="width: 100%; margin: 3px 0;">Accept all cookies</Button>
            </CookiesViewSettingsButtonsWrapper>
        </CookiesViewSettingsWrapper>
    )
}

export default CookiesView;