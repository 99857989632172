import styled from "styled-components";
import { ImageViewProps } from "./ImageView";

export const ImageViewWrapper = styled.div.attrs((props: ImageViewProps) => {
    return {
        width: props.width,
        height: props.height,
        borderRadius: props.borderRadius,
        imagePadding: props.imagePadding,
        extendCss: props.extendCss,
    };
  })`
      display: inline-flex;
      width: ${(props) => props.width ? props.width : "auto"};
      height: ${(props) => props.height ? props.height : "auto"};
      flex-direction: row;
      justify-content: center;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        padding: ${(props) => props.imagePadding ? props.imagePadding : "0px"};
    }

      border-radius: ${(props) => props.borderRadius ? props.borderRadius : "0px"};

      ${(props) => props.extendCss};
  `;
