import styled from "styled-components";
import { FooterProps } from "./Footer";

export const FooterParentWrapper = styled.div.attrs((props: FooterProps) => {
  return {
      extendCss: props.extendCss,
  };
})`
    display: flex;
    width: 100%;
    max-width: 100%;
    min-height: 640px;
    justify-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: 0px) and (max-width: 960px) {
      width: 100%;
    }

    ${(props) => props.extendCss};
`;

export const FooterWrapper = styled.div.attrs((props: FooterProps) => {
    return {
        backgroundColor: props.backgroundColor,
        overlayImageUrl: props.overlayImageUrl,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      justify-items: center;
      justify-content: center;
      min-height: 320px;
      width: 100%;
      max-width: 100%;
      overflow: hidden;

      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#ffffff")};

      background-image: url(${(props) => props.overlayImageUrl});
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      -webkit-background-size: cover;

      -webkit-box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.2);
      box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.2);

      @media (min-width: 0px) and (max-width: 960px) {
        width: 100%;
      }

      ${(props) => props.extendCss};
  `;

  export const FooterAnimWrapper = styled.div.attrs((props: FooterProps) => {
    return {
        backgroundColor: props.backgroundColor,
        overlayImageUrl: props.overlayImageUrl,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      max-width: 100%;
      max-height: 100%;
      overflow-x: hidden;

      #moon {
        display: inline-flex;
        position: absolute;
        animation: rotate 30s linear infinite;
        margin: calc(-45vh - 100px) 0 0 20vw;
      }
      
      #star-1 {
        display: inline-flex;
        position: absolute;
        animation: dance 5s linear infinite;
        margin: calc(-45vh + 150px) 0 0 calc(-45vw - 20px);
      }
      
      #ufo-1 {
        width: auto;
        display: inline-flex;
        position: absolute;
        animation: ufo_move 12s infinite;
        margin: calc(-45vh + 10px) 0 0 0;
      }
      
      #ufo-2 {
        width: auto;
        display: inline-flex;
        position: absolute;
        animation: ufo_move 16s infinite;
        margin: calc(-45vh + 50px) 0 0 0;
      }
      #ufo-3 {
        width: auto;
        display: inline-flex;
        position: absolute;
        animation: ufo_move 19s infinite;
        margin: calc(-45vh + 100px) 0 0 0;
      }
      @keyframes ufo_move {
      0% {
        display: inline;
        opacity: 0;
        transform: translatex(-50vw);
      }
      20% {
        opacity: 1;
        transform: translatex(-17vw);
      }
      50% {
        opacity: 1;
        transform: translatex(25vw);
      }
      70% {
        opacity: 0;
      }
      100% {
        opacity: 0;
        transform: translatex(-100vw);
      }
    }

      @keyframes rotate {
        0% {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes dance {
        0% {
          transform: rotate(-25deg);
        }
        50% {
          transform: rotate(25deg);
        }
        100% {
          transform: rotate(-25deg);
        }
      }
}

  `;
  
  export const FooterInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin-top: 56px;
`;

export const FooterGoTopWrapper = styled.div`
    position: fixed;
    right: 56px;
    bottom: 56px;
    cursor: pointer;
    z-index: 20;
    
    @media (min-width: 0px) and (max-width: 960px) {
      right: 32px;
      bottom: 32px;
  }
`;

export const FooterListWrapper = styled.ul`
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
    list-style-type: none;
    gap: 32px;
    padding: 0;
    margin: 24px 0;

    a {
      position: relative;
      text-decoration: none;
      font-weight: 500;
    }

    a:link, a:visited, a:active {
        color: #2b2d42;
    }

    a:hover {
        color: #000;
    }
    
    a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        bottom: -10px;
        background-color: #000;
        transform: scaleX(0);
        transition: transform 0.3s ease;
    }
    
    a:hover::before {
        transform: scaleX(1);
    }

    @media (min-width: 0px) and (max-width: 960px) {
      align-items: center;
      justify-items: center;
      justify-content: center;
      flex-direction: column;

      a {
        font-size: calc(18px + 1vmin);
      }

      text-align: center;
  }
`;

export const FooterIconListWrapper = styled.ul`
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
    list-style-type: none;
    gap: 32px;
    padding: 0;
    margin: 5% 0;
`;

export const FooterListItemWrapper = styled.li.attrs(
  (props: FooterProps) => {
    return {
      extendCss: props.extendCss,
    };
  }
)`
    display: inline-flex;
    list-style: none;
    font-size: calc(10px + 1.4vmin);
    flex-wrap: nowrap;

  ${(props) => props.extendCss};
`;

export const FooterListItemSmallWrapper = styled.li.attrs(
  (props: FooterProps) => {
    return {
      extendCss: props.extendCss,
    };
  }
)`
    display: inline-flex;
    list-style: none;
    font-size: calc(10px + 1vmin);
    flex-wrap: nowrap;
    cursor: pointer;

  ${(props) => props.extendCss};
`;

export const FooterCopyrightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    margin-bottom: 20vh;
    font-size: calc(9px + 1vmin);
`;

export const FooterSpikesWrapper = styled.div`
  margin-top: -100px;
  background-image: url('/assets/images/style_spikes.svg');
  background-repeat: repeat-x;
  background-position: top;
  background-size: 56px 150px;
`;
