import { FieldError, FieldErrors, FieldErrorsImpl, FieldValues, Merge, RegisterOptions, useFormContext } from "react-hook-form";
import { AnimatePresence, motion } from 'framer-motion'
import { getInputError, isFormInvalid } from "../../../../utils/ValidationHelper";
import { InputViewFieldWrapper, InputViewWrapper } from "./InputView.style";
import { DetailedHTMLProps, TextareaHTMLAttributes } from "react";

export interface InputViewProps {
    id?: string;
    name: string;
    label: string;
    value?: string;
    placeholder: string;
    multiline?: boolean;
    type?: any | DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
    validation: RegisterOptions<FieldValues, string> | undefined;
    extendCss?: string;
    extendClass?: string;
}

export interface InputErrorProps {
    message: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

export const InputView = ({ ...props }: InputViewProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    const inputErrors: FieldErrors<FieldValues> = getInputError(errors, props.name)
    const isInvalid = isFormInvalid(inputErrors)

    return (
        <InputViewWrapper>
            <InputViewFieldWrapper>
                <label htmlFor={props.id}>
                    {props.label}
                </label>
                <AnimatePresence mode="wait" initial={false}>
                    {isInvalid && (
                        <InputError message={inputErrors.error?.message} />
                    )}
                </AnimatePresence>
            </InputViewFieldWrapper>

            {props.multiline ? (
                <textarea
                id={props.id}
                value={props.value}
                placeholder={props.placeholder}
                {...register(props.name, props.validation)}
                ></textarea>
            ) : (
                <input
                id={props.id}
                type={props.type}
                value={props.value}
                placeholder={props.placeholder}
                {...register(props.name, props.validation)} />
            )}

        </InputViewWrapper>
    )
}

const InputError = ({ message }: InputErrorProps) => {
    return (
      <motion.p
        className="input-error-view"
        {...framerAnimation}
      >
        <>
            <span className="material-symbols-rounded">error</span>
            {message}
        </>
      </motion.p>
    )
}

const framerAnimation = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
    transition: { duration: 0.2 },
}