import { Link } from "react-router-dom";
import { ButtonWrapper } from "./Button.style";

export type ButtonMode =
  | "theme"
  | "dark"
  | "light";

export type ButtonSize = "small" | "medium" | "large" | "x-large";

export interface ButtonProps {
  label?: string;
  width?: string;
  height?: string;
  size?: ButtonSize;
  mode?: ButtonMode;
  disabled?: boolean;
  type?: "button" | "submit";
  path?: string;
  children?: any;
  onClick?: () => void | undefined | Promise<void>;
  extendClass?: string;
  extendCss?: string;
}

const Button = ({
  size = "x-large",
  mode = "theme",
  type = "button",
  ...props
}: ButtonProps) => {
  const linkButton = (
    <Link to={props.path!}>
      <ButtonWrapper
        className={mode}
        disabled={props.disabled ? true : false}
        onClick={props.onClick ? props.onClick : undefined}
        size={size}
        {...props}
      >
        {props.label}
        {props.children}
      </ButtonWrapper>
    </Link>
  );

  const defaultButton = (
    <ButtonWrapper
      className={mode}
      disabled={props.disabled ? true : false}
      onClick={props.onClick ? props.onClick : undefined}
      size={size}
      {...props}
    >
      {props.path ? linkButton : props.label} {props.children}
    </ButtonWrapper>
  );

  return props.path ? linkButton : defaultButton;
};

export default Button;