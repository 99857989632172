import styled from "styled-components";
import { CardViewProps } from "./CardView";

export const CardViewWrapper = styled.li.attrs((props: CardViewProps) => {
    return {
        onClick: props.onClick,
        hideShadow: props.hideShadow,
        minHeight: props.minHeight,
        hoverColor: props.hoverColor,
        backgroundColor: props.backgroundColor,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      position: relative;
      justify-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: calc(20vw + 12px);
      min-width: calc(15vw + 12px);
      min-height: ${(props) => props.minHeight};
      border-radius: 0.3em;
      padding: 0 12px;
      gap: 64px;

      ${(props) => (props.onClick ? "cursor: pointer; z-index: 2;" : "")};

      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#ffffff")};

      :hover {
        color: #000;
      }
    
      ::before {
          content: "";
          position: absolute;
          display: block;
          width: 100%;
          height: 3px;
          bottom: -10px;
          background-color: ${(props) => (props.hoverColor ? props.hoverColor : "none")};
          transform: scaleX(0);
          transition: transform 0.3s ease;
      }
      
      :hover::before {
          transform: scaleX(1);
      }

      ${(props) => (props.hideShadow ? "" : "-webkit-box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.2); box-shadow: 0px 2px 3px 0px rgba(50, 50, 50, 0.2);")}

      @media (min-width: 0px) and (max-width: 960px) {
        width: 100%;
      }
      
      span {
        display: inline-flex;
        position: absolute;
        color: #2ad1ff;
        justify-items: center;
        items-align: center;
        gap: 12px;
        bottom: -12px;
      }

      ${(props) => props.extendCss};
  `;

  export const CardViewInnerWrapper = styled.div.attrs((props: CardViewProps) => {
    return {
        titleColor: props.titleColor,
        descriptionColor: props.descriptionColor
    };
  })`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 24px;

    h4 {
        margin: 0;
        padding: 0;
        white-space: pre-line;
        color: ${(props) => (props.titleColor ? props.titleColor : "#000")};
    }

    p {
        font-size: calc(12px + 1vmin);
        white-space: pre-line;
        color: ${(props) => (props.descriptionColor ? props.descriptionColor : "#000")};
    }
`;
