import HtmlParse from 'htmr';

import db from "../../../../data/db.json";

import CardView from "../../atoms/CardView/CardView";
import ImageView from "../../atoms/ImageView/ImageView";
import SegmentView, { GradientColorsProps } from "../../atoms/SegmentView/SegmentView";
import TitleView from "../../atoms/TitleView/TitleView";
import Footer from "../../molecules/Footer/Footer";
import Header from "../../molecules/Header/Header";
import Button from '../../atoms/Button/Button';


const StartPage = () => {

    //Slogan: A roaring digital transformation

    //start -> story
    const storyId = db.data.pages.start.story.id
    const storyTitle = db.data.pages.start.story.title
    const storyDescription = db.data.pages.start.story.text

    //start -> services
    const servicesId = db.data.pages.start.services.id
    const servicesTitle = db.data.pages.start.services.title
    const servicesDescription = db.data.pages.start.services.text
    const serviceCards = db.data.pages.start.services.cards

    //start -> development
    const developmentId = db.data.pages.start.development.id
    const developmentTitle = db.data.pages.start.development.title
    const developmentDescription = db.data.pages.start.development.text
    const developmentImage = db.data.pages.start.development.image.src
    const developmentImageHeight = db.data.pages.start.development.image.height

    //start -> industry experience
    const industryExperienceId = db.data.pages.start.industryExperience.id
    const industryExperienceTitle = db.data.pages.start.industryExperience.title
    const industryExperienceDescription = db.data.pages.start.industryExperience.text
    const industryExperienceCards = db.data.pages.start.industryExperience.cards

    //start -> tech stack
    const techStackId = db.data.pages.start.techStack.id
    const techStackTitle = db.data.pages.start.techStack.title
    const techStackDescription = db.data.pages.start.techStack.text
    const techStackImages = db.data.pages.start.techStack.images

    //start -> collaboration process
    const collaborationProcessId = db.data.pages.start.collaborationProcess.id
    const collaborationProcessTitle = db.data.pages.start.collaborationProcess.title
    const collaborationProcessDescription = db.data.pages.start.collaborationProcess.text
    const collaborationProcessCards = db.data.pages.start.collaborationProcess.cards

    //navigation
    const navigation = db.data.navigation

    //footer
    const copyrightText = HtmlParse(db.data.copyright.text)
    const businessId = db.data.company.id

    //segment gradient colors
    const segmentViewGradientBackgroundColors: GradientColorsProps = {
        startColor: "rgba(31,38,43,1)",
        startPercentage: "0%",
        endColor: "rgb(22, 29, 34)",
        endPercentage: "100%"
    }

    return (
        <>
            <Header carousel={true} links={navigation.menu} id="start" key="start" overlayImageUrl='/assets/images/style_header_curve.svg' />
            
            <SegmentView id={storyId} extendCss='background-color: #ffffff; padding-top: 64px;' minHeight='auto'>
                <ImageView src={"logo/" + db.data.company.logo + ".svg"} alt={db.data.company.name} height='calc(10vw + 120px)' />
                <TitleView color='#000000' title={storyTitle} description={storyDescription} />
                <Button label='READ MORE' path='/company' />
            </SegmentView>

            <SegmentView id={servicesId} minHeight='auto' extendCss='background-color: #161d22;'>
                <TitleView title={servicesTitle} description={servicesDescription} />
                {serviceCards?.map((item, index) => (
                    <CardView key={"service_card_" + item + "_" + index} title={item.title} description={item.text} />
                ))}
            </SegmentView>

            <SegmentView id="contact_us_button" minHeight='auto' extendCss='background-color: #161d22;'>
                <Button label='CONTACT US' path='/contact' />
            </SegmentView>

            <hr className='linew' />

            <SegmentView id={industryExperienceId} extendCss='background-color: #161d22;'>
                <TitleView title={industryExperienceTitle} description={industryExperienceDescription} />
                {industryExperienceCards?.map((item, index) => (
                    <CardView key={industryExperienceId + "_card_" + item + "_" + index} title={item.title} description={item.text} />
                ))}
            </SegmentView>

            <hr className='linew' />

            <SegmentView id={industryExperienceId} extendCss='background-color: #161d22;'>
                <TitleView title={collaborationProcessTitle} description={collaborationProcessDescription} />
                {collaborationProcessCards?.map((item, index) => (
                    <CardView key={collaborationProcessId + "_card_" + item + "_" + index} title={item.title} description={item.text} />
                ))}
            </SegmentView>
            
            <SegmentView id={techStackId} extendCss='background-color: #ffffff;' minHeight='auto'>
            <TitleView color='#000000' title={techStackTitle} description={techStackDescription} />
                {techStackImages?.map((item, index) => (
                    <ImageView key={techStackId + "_item_" + item + "_" + index} src={item.src + ".svg"} width={item.width} height={item.height} imagePadding='32px' borderRadius='100%' />
                ))}
            </SegmentView>

            <Footer links={navigation.footer} social={navigation.social} copyright={copyrightText} businessId={businessId} />
        </>
    );
};

export default StartPage;