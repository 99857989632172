import styled from "styled-components";
import { CookiesViewProps } from "./CookiesView";

export const CookiesViewWrapper = styled.div.attrs((props: CookiesViewProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      position: fixed;
      bottom: 10px;
      left: 10px;
      z-index: 100;
      padding: 0 24px;
      width: calc(25vw + 2vmin);
      min-width: 480px;
      max-width: 640px;
      min-height: 320px;
      justify-items: center;
      flex-direction: column;
      background-color: #ffffff;
      border: #0b0a0a solid 2px;
      border-radius: 6px;

      p {
        text-align: left;
        font-size: calc(10px + 1vmin);
      }

      @media (min-width: 0px) and (max-width: 960px) {
        bottom: 10px;
        left: 10px;
        right: 10px;
        min-width: 240px;
        width: auto;

        p {
            text-align: left;
            font-size: calc(14px + 1vmin);
        }
      }

      ${(props) => props.extendCss};
  `;

  export const CookiesViewSettingsWrapper = styled.div.attrs((props: CookiesViewProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      z-index: 100;
      padding: 0 24px;
      width: calc(25vw + 2vmin);
      min-width: 480px;
      max-width: 640px;
      height: 100%;
      flex-direction: column;
      background-color: #ffffff;
      border-right: #0b0a0a solid 2px;

      p {
        text-align: left;
        font-size: calc(10px + 1vmin);
        margin: 12px 0;
      }

      @media (min-width: 0px) and (max-width: 960px) {
        min-width: 320px;
        width: auto;
        height: auto;

        p {
            text-align: left;
            font-size: calc(14px + 1vmin);
        }
      }

      ${(props) => props.extendCss};
  `;

  export const CookiesViewTitleWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: 80px;
  `;

  export const CookiesViewTextWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: 500px;
      overflow-y: auto;
      white-space: pre-line;
  `;

  export const CookiesViewSettingsTextWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      white-space: pre-line;
  `;

  export const CookiesViewSettingsOptionsWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: auto;

      h4 {
        text-align: left;
      }

      h5 {
        text-align: left;
        margin: 0;
      }
  `;

  export const CookiesViewSettingsOptionTextWrapper = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  `;

  export const CookiesViewSettingsOptionItemWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: auto;
      margin: 12px 0;

      p {
        padding-right: 12px;
      }
  `;

  export const CookiesViewSettingsButtonsWrapper = styled.div`
      display: flex;
      flex-direction: column;
      height: calc(20vh + 100px);
      padding: 24px 0;
  `;

  export const CookiesViewButtonsWrapper = styled.div`
      display: flex;
      flex-direction: column;
      padding: 24px 0;
  `;
