import CompanyPage from "../components/ui/pages/CompanyPages/CompanyPage";
import CookiesPage from "../components/ui/pages/CompanyPages/CookiesPage";
import PrivacyPage from "../components/ui/pages/CompanyPages/PrivacyPage";
import TermsPage from "../components/ui/pages/CompanyPages/TermsPage";
import ContactPage from "../components/ui/pages/ContactPage/ContactPage";
import StartPage from "../components/ui/pages/StartPage/StartPage";

interface RouteProps {
    name: string;
    path: string;
    component: any;
    routes?: Array<RouteProps>;
}

export const routes: Array<RouteProps> = [
    {
        name: "Start",
        path: "/",
        component: <StartPage />,
    },
    {
        name: "Contact",
        path: "/contact",
        component: <ContactPage />,
    },
    {
        name: "Privacy",
        path: "/privacy",
        component: <PrivacyPage />,
    },
    {
        name: "Company",
        path: "/company",
        component: <CompanyPage />,
        routes: [
            {
                name: "Terms",
                path: "/company/terms",
                component: <TermsPage />
            },
            {
                name: "Cookies",
                path: "/company/cookies",
                component: <CookiesPage />
            },
        ]
    }
];

export const getRoutes = (): Array<RouteProps> => {
    return routes;
};