import HtmlParse from 'htmr';

import db from "../../../../data/db.json";

import Header from '../../molecules/Header/Header';
import Footer from '../../molecules/Footer/Footer';
import SegmentView from '../../atoms/SegmentView/SegmentView';
import TitleView from '../../atoms/TitleView/TitleView';
import ImageTextView from '../../molecules/ImageTextView/ImageTextView';
import CardView from '../../atoms/CardView/CardView';
import Button from '../../atoms/Button/Button';
import ImageView from '../../atoms/ImageView/ImageView';

const CompanyPage = () => {

    //story
    const storyTitle = db.data.pages.start.story.title
    const storySubtitle = db.data.pages.start.story.subtitle
    const storyDescription = db.data.pages.start.story.detail

    //title
    const id = db.data.pages.company.general.id
    const title = db.data.pages.company.general.title
    const description = db.data.pages.company.general.text

    //start -> quality
    const qualityId = db.data.pages.start.quality.id
    const qualityTitle = db.data.pages.start.quality.title
    const qualityDescription = db.data.pages.start.quality.text
    const qualityImage = db.data.pages.start.quality.image.src
    const qualityImageHeight = db.data.pages.start.quality.image.height

    //navigation
    const navigation = db.data.navigation

    //footer
    const copyrightText = HtmlParse(db.data.copyright.text)
    const businessId = db.data.company.id

    const logo = "logo/" + db.data.company.logo + ".svg"

    return (
        <>
            <Header links={navigation.menu} id="start" key="start" overlayImageUrl='/assets/images/style_header_curve.svg' />

            <SegmentView id="company-intro" extendCss='background-color: #ffffff;' minHeight='auto'>
                <ImageView src={logo} alt={db.data.company.name} height='320px' />
                <TitleView color='#000000' title={storySubtitle} description={storyDescription} />
            </SegmentView>

            <hr className='line' />

            <SegmentView id='company-values' extendCss='background-color: #ffffff'>
                <TitleView 
                color='#000000' 
                title='OUR VALUES' description='Following competencies that define the overarching strength and capabilities of the entire company and aligned with the company`s vissions, missions and values.' />

                <CardView title='Customer-oriented' />
                <CardView title='Genuine' />
                <CardView title='Emphathy' />
                <CardView title='Passion' />
                <CardView title='Efficient' />
                <CardView title='Flexibility' />
                <CardView title='Inclusion' />
                <CardView title='Integrity' />
                <CardView title='Honesty' />
                <CardView title='Trust' />
                <CardView title='Curiosity' />
                <CardView title='Thriving' />
                <CardView title='Innovation' />
            </SegmentView>

            <hr className='line' />

            <SegmentView id='strength' extendCss='background-color: #ffffff'>
                <TitleView 
                color='#000000' 
                title='CORE STRENGTHS' description='These are company wide core strengths of Leijonic.' />

                <CardView title='Project management' />
                <CardView title='Product innovation' />
                <CardView title='Consistency and reliability' />
                <CardView title='Customer focused' />
                <CardView title='Experienced engineering' />
                <CardView title='Machine learning' />
                <CardView title='Design thinking' />
                <CardView title='Agility and adaptability' />
                <CardView title='Strategic thinking and planning' />
                <CardView title='Customer experience and creativity' />
                <CardView title='Multiple industries experience' />
                <CardView title='Collaboration and communication' />
                <CardView title='Data driven' />
                <CardView title='Scale sustainably' />
                <CardView title='Modern tech stack' />
                <CardView title='Privacy and security' />
                <CardView title='Time management' />
                <CardView title='Nordic company' />
            </SegmentView>

            <SegmentView extendCss='background-color: #ffffff; padding: 5% 0;' minHeight='auto'>
                <Button label='CONTACT US' path='/contact' />
            </SegmentView>

            <Footer links={navigation.footer} social={navigation.social} copyright={copyrightText} businessId={businessId} />
        </>
    );
}

export default CompanyPage;