import { useEffect, useState } from "react";
import { CarouselBulletsWrapper, CarouselBulletWrapper, CarouselItemWrapper, CarouselWrapper } from "./Carousel.style";

export interface CarouselProps {
    id?: string;
    imageUrl?: string;
    imageUrls?: string[];
    autoStart?: boolean;
    speed?: number;
    extendCss?: string;
}

const Carousel = ({speed = 5, ...props}: CarouselProps) => {

    var data = [
        "./assets/images/backgrounds/we_design_and_build.png",
    ];

    if (props.imageUrls && props.imageUrls.length > 0) {
        data = props.imageUrls
    }

    const [currentPosition, setCurrentPosition] = useState(0);

    const loopScroll = () => {
        if(currentPosition == data.length -1) {
            return setCurrentPosition(0);
        } 
        return setCurrentPosition(currentPosition + 1);
    }

    const onChangePosition = (position: number) => {
        setCurrentPosition(position);
    }

    useEffect(() => {
        const intervalId = setInterval(() => loopScroll(), 1000 * speed);
        return () => clearInterval(intervalId);
    }, [currentPosition, speed]);

    return (
    <CarouselWrapper>
        {data.map((item, index) => (
        <CarouselItemWrapper
        key={"carousel_item_" + index}
        imageUrl={item}
        style={{
            transform: `translateX(-${currentPosition * 100}%)`,
        }} />
        ))}

        <CarouselBulletsWrapper>
        {data.length > 1 && data.map((_, index) => (
            <CarouselBulletWrapper
            className={currentPosition == index ? "active" : ""}
            key={"carousel_bullet_" + index}
            onClick={() => onChangePosition(index)}
             />
        ))}
        </CarouselBulletsWrapper>
    </CarouselWrapper>
    );
};

export default Carousel;