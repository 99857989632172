import styled from "styled-components";
import { ContactPageProps } from "./ContactPage";

export const ContactPageWrapper = styled.div.attrs((props: ContactPageProps) => {
    return {
        backgroundGradientColors: props.backgroundGradientColors,
        backgroundImageUrl: props.backgroundImageUrl,
        extendCss: props.extendCss,
    };
  })`

  background-image: url(${(props) => props.backgroundImageUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  
  background: linear-gradient(
    ${(props) => props.backgroundGradientColors?.rotationDegree ? props.backgroundGradientColors?.rotationDegree : "180" }deg,
    ${(props) => props.backgroundGradientColors?.startColor ? props.backgroundGradientColors?.startColor : "rgba(31,38,43,1)" } 
    ${(props) => props.backgroundGradientColors?.startColor ? props.backgroundGradientColors?.startPercentage : "0%" }, 
    ${(props) => props.backgroundGradientColors?.endColor ? props.backgroundGradientColors?.endColor : "rgba(22,29,34,1)" } 
    ${(props) => props.backgroundGradientColors?.endPercentage ? props.backgroundGradientColors?.endPercentage : "100%" }
    );

`;
