import styled from "styled-components";
import { GoTopIconProps } from "./GoTopIcon";

export const GoTopIconWrapper = styled.div.attrs((props: GoTopIconProps) => {
    return {
      extendCss: props.extendCss,
    };
  })`
    position: fixed;
    right: 56px;
    bottom: 56px;
    cursor: pointer;
    z-index: 20;
    
    @media (min-width: 0px) and (max-width: 960px) {
      right: 32px;
      bottom: 32px;
    }

    ${(props) => props.extendCss};
`;