import styled from "styled-components";
import { CarouselProps } from "./Carousel";

export const CarouselWrapper = styled.div.attrs((props: CarouselProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      position: relative;
      display: flex;
      overflow: hidden;
      justify-items: center;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: auto;
      transition: all 0.3s ease-in-out;
      color: #ffffff;
      margin-top: 60px;

      ${(props) => props.extendCss};
  `;

    export const CarouselItemWrapper = styled.div.attrs((props: CarouselProps) => {
        return {
            imageUrl: props.imageUrl,
            extendCss: props.extendCss,
        };
    })`
        display: flex;
        min-width: 100%;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
        font-size: calc(24px + 1vmin);
        z-index: -1;

        background-image: url(${(props) => props.imageUrl});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        -webkit-background-size: cover;
    `;

    export const CarouselBulletsWrapper = styled.ul`
        position: absolute;
        display: flex;
        width: 100%;
        height: 160px;
        justify-content: center;
        align-items: center;
        list-style-type: none;
        flex-wrap: nowrap;
        gap: 24px;
        bottom: 0px;

        @media (min-width: 0px) and (max-width: 960px) {
            gap: 36px;
        }
  `;
  
    export const CarouselBulletWrapper = styled.li`
        display: inline-flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        list-style: none;
        background-color: #ffffff;
        cursor: pointer;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        z-index: 2;
    
        &.active {
            background-color: #0266c3;
        }

        :hover {
            background-color: #0266c3;
        }

        @media (min-width: 0px) and (max-width: 960px) {
            width: 16px;
            height: 16px;
        }
    `;