import { useContext } from "react";
import { SettingsContext } from "../../../../App";
import { HashLink as Link } from "react-router-hash-link";
import ImageView from "../../atoms/ImageView/ImageView";
import SegmentView, { GradientColorsProps } from "../../atoms/SegmentView/SegmentView";
import { NavigationItemProps } from "../TopBar/TopBar";
import { FooterWrapper, FooterInnerWrapper, FooterListWrapper, FooterListItemWrapper, FooterGoTopWrapper, FooterParentWrapper, FooterCopyrightWrapper, FooterListItemSmallWrapper, FooterSpikesWrapper, FooterAnimWrapper } from "./Footer.style";
import CookiesConsent from "../../../features/Cookies/CookiesConsent";
import GoTop from "../../../features/GoTop/GoTop";

export interface FooterProps {
    id?: string;
    animate?: boolean;
    spikesHidden?: boolean;
    copyright: string | any;
    businessId: string | any;
    social: Array<NavigationItemProps>;
    links: Array<NavigationItemProps>;
    generalLinks: Array<NavigationItemProps>;
    backgroundColor?: string;
    overlayImageUrl?: string;
    extendCss?: string;
}

export interface CopyrightProps {
    text: string;
}

const Footer = ({ ...props }: FooterProps) => {
    const { displayExtendedCookiesPanel, setDisplayExtendedCookiesPanel } = useContext(SettingsContext);

    return (
        <FooterParentWrapper>

            <CookiesConsent />

            {props.spikesHidden && <SegmentView minHeight="300px" />}
            {props.animate && !props.spikesHidden && <FooterSpikesWrapper><SegmentView minHeight="500px" /></FooterSpikesWrapper>}

            <SegmentView minHeight='auto'>
                <img className='decoration' src='/assets/images/style_footer_curve.svg' />
            </SegmentView>

            <FooterWrapper backgroundColor={props.backgroundColor} overlayImageUrl={props.overlayImageUrl}>
            
            {props.animate && 
                <FooterAnimWrapper>
                    <ImageView id="moon" src="./icons/moon.svg" height="96px" width="96px" />
                    <ImageView id="star-1" src="./icons/star.svg" height="46px" />
                    <ImageView id="ufo-1" src="./icons/ufo.svg" height="36px" />
                    <ImageView id="ufo-2" src="./icons/ufo.svg" height="46px" />
                    <ImageView id="ufo-3" src="./icons/ufo.svg" height="56px" />
                </FooterAnimWrapper>
            }

            <FooterInnerWrapper>
                <GoTop id="nav_go_to_top" />

                <FooterListWrapper>
                    {props.links?.map((item, index) => (
                        <FooterListItemWrapper key={"nav_footer_link_container_" + item + "_" + index} className="link" extendCss={props.extendCss}>
                            <Link key={"nav_footer_" + item + "_" + index} to={item.path} ><b>{item.label}</b></Link>
                        </FooterListItemWrapper>
                    ))}
                </FooterListWrapper>

                <hr className="line" />

                <FooterListWrapper>
                    {props.generalLinks?.map((item, index) => (
                        <FooterListItemSmallWrapper key={"nav_footer_general_link_container_" + item + "_" + index} className="link" extendCss={props.extendCss}>
                            <Link key={"nav_general_footer_" + item + "_" + index} to={item.path} ><b>{item.label}</b></Link>
                        </FooterListItemSmallWrapper>
                    ))}
                        <FooterListItemSmallWrapper key={"nav_footer_general_link_container_cookies_settings"} className="link" extendCss={props.extendCss}>
                            <a key={"nav_general_footer_cookies_settings"} onClick={() => setDisplayExtendedCookiesPanel?.(!displayExtendedCookiesPanel)}><b>Cookies settings</b></a>
                        </FooterListItemSmallWrapper>
                </FooterListWrapper>

                <hr className="line" />

                <FooterCopyrightWrapper>
                    <label className="copyright">
                        {props.copyright}
                    </label>
                    <label id="business_id" className="copyright">
                        Business ID: <span>{props.businessId}</span>
                    </label>
                </FooterCopyrightWrapper>

            </FooterInnerWrapper>
        </FooterWrapper>
    </FooterParentWrapper>
    );
};

Footer.defaultProps = {
    animate: true,
    copyright: {
        text: "Copyright",
        year: "2025",
        company: "company.com"
    },
    links: [
        { "label": "Our Story", "path": "/#story" },
        { "label": "Services", "path": "/#services" },
        { "label": "FAQ", "path": "/#faq" }
      ],
      generalLinks: [
        { "label": "Company information", "path": "/company" },
        { "label": "Privacy policy", "path": "/privacy" }
      ]
}

export default Footer;