import Cookies from 'universal-cookie';

/**
 * List of cookie configuration and keys
 * 
 * when you make changes here, also make changes in 'CookiesView.tsx'
 */
const COOKIES_EXPIRE_IN_DAYS = 1 //expire in 1 day(s) from the day its set

const KEY_CONSENT_NECESSARY_COOKIES = "necessaryCookies"
const KEY_CONSENT_PERFORMANCE_COOKIES = "performanceCookies"
const KEY_CONSENT_FUNCTIONAL_COOKIES = "functionalCookies"

/**
 * Helper function to store cookies
 * 
 * @param {string} key 
 * @param {any} value 
 * @param {string} path 
 * @param {number} expiresInDays 
 */
export const storeCookies = (key, value, path = "/", expireInDays = COOKIES_EXPIRE_IN_DAYS) => {
    const cookies = new Cookies();
    const expiryDate = new Date()

    expiryDate.setDate(expiryDate.getDate() + expireInDays)
    cookies.set(key, value, { path: path, expires: expiryDate, secure: true });
}

/**
 * Returns boolean when cookie is available by the key
 * @param {string} key 
 * @returns 
 */
export const isCookies = (key) => {
    const cookies = new Cookies();
    return cookies.get(key);
}

/**
 * Returns a boolean when cookie consent was given
 * @returns 
 */
export const isCookiesConsentGiven = () => {
    const cookies = new Cookies();
    return cookies.get(KEY_CONSENT_NECESSARY_COOKIES) === true || 
           cookies.get(KEY_CONSENT_PERFORMANCE_COOKIES) === true || 
           cookies.get(KEY_CONSENT_FUNCTIONAL_COOKIES) === true;
}

/**
 * Remove stored cookie consent data
 * @param {string} path 
 */
export const clearCookieConsentData = () => {
    const cookies = new Cookies();
    cookies.remove(KEY_CONSENT_NECESSARY_COOKIES);
    cookies.remove(KEY_CONSENT_PERFORMANCE_COOKIES);
    cookies.remove(KEY_CONSENT_FUNCTIONAL_COOKIES);
}