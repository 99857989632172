import styled from "styled-components";
import { HeaderProps } from "./Header";

export const HeaderWrapper = styled.div.attrs((props: HeaderProps) => {
    return {
        carousel: props.carousel,
        overlayImageUrl: props.overlayImageUrl,
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      justify-items: center;
      justify-content: center;
      min-height: ${(props) => props.carousel ? "800px" : "90px"};
      width: 100%;
      height: 100%;
      margin-bottom: -10px;

      background-image: url(${(props) => props.overlayImageUrl});
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      -webkit-background-size: cover;

      @media (min-width: 0px) and (max-width: 640px) {
        ${(props) => props.carousel ? "min-height: 36vh;" : ""};
      }

      @media (min-width: 640px) and (max-width: 960px) {
        ${(props) => props.carousel ? "min-height: 45vh;" : ""};
      }

      @media (min-width: 960px) and (max-width: 1280px) {
        ${(props) => props.carousel ? "min-height: 55vh;" : ""};
      }

      @media (min-width: 2920px) {
        ${(props) => props.carousel ? "min-height: 85vh;" : ""};
      }

      ${(props) => props.extendCss};
  `;
