import styled from "styled-components";
import { AlertViewProps } from "./AlertView";

export const AlertViewWrapper = styled.div.attrs((props: AlertViewProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: flex;
      position: fixed;
      top: 80px;
      width: 100%;
      height: auto;
      min-height: 120px;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      z-index: 9;
      gap: 32px;

      background-color: rgba(0,0,0, 0.8);

      @media (min-width: 0px) and (max-width: 960px) {
        width: 100%;
      }

      &.animation-enter {
        animation: anim-enter 0.5s ease-in;
      }

      &.animation-exit {
        animation: anim-exit 2s ease-out;
        animationFillMode: forwards;
      }

      @keyframes anim-enter {
        0% {
          opacity: 0;
          transform: translatey(-150px);
        }
        100% {
          opacity: 1;
          transform: translatey(0px);
        }
      }

      @keyframes anim-exit {
        0% {
          opacity: 1;
          transform: translatey(0px);
        }
        100% {
          opacity: 0;
          transform: translatey(-150px);
        }
      }

      ${(props) => props.extendCss};
  `;

export const AlertInnerViewWrapper = styled.div.attrs((props: AlertViewProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
      gap: 32px;

      p {
        color: #fff;
      }

      @media (min-width: 0px) and (max-width: 960px) {
        margin: 0 100px 0 24px;
      }

      ${(props) => props.extendCss};
  `;

  export const AlertCloseViewWrapper = styled.div`
      display: inline-flex;
      position: absolute;
      align-items: flex-end;
      right: 12px;
      padding: 24px;
      cursor: pointer;
  `;
