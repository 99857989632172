import HtmlParse from 'htmr';

import db from "../../../../data/db.json";

import Header from '../../molecules/Header/Header';
import Footer from '../../molecules/Footer/Footer';
import SegmentView, { GradientColorsProps } from '../../atoms/SegmentView/SegmentView';
import Contact from '../../../features/Contact/Contact';
import TitleView from '../../atoms/TitleView/TitleView';
import CardView from '../../atoms/CardView/CardView';
import { ContactPageWrapper } from './ContactPage.style';
import ImageView from '../../atoms/ImageView/ImageView';

export interface ContactPageProps {
    backgroundGradientColors?: GradientColorsProps,
    backgroundImageUrl: string;
    extendCss?: string;
}

const ContactPage = () => {
    
    //start -> contact
    const contactTitle = db.data.pages.start.contact.title
    const contactDescription = db.data.pages.start.contact.description

    const contactGeneralImage = db.data.pages.start.contact.general.image
    const contactGeneralTitle = db.data.pages.start.contact.general.title
    const contactGeneralDescription = db.data.pages.start.contact.general.text

    const companyEmailLabel = db.data.pages.start.contact.labels.email
    const companyEmail = db.data.company.email

    const companyPhoneLabel = db.data.pages.start.contact.labels.phone
    const companyPhone = db.data.company.phone

    const companyAddressLabel = db.data.pages.start.contact.labels.location
    const companyAddress = db.data.company.address

    //navigation
    const navigation = db.data.navigation

    //footer
    const copyrightText = HtmlParse(db.data.copyright.text)
    const businessId = db.data.company.id

    //card click
    const onClickContactCard = (e: MouseEvent) => {
        e.preventDefault();

        const id = (e.currentTarget as HTMLElement).dataset.id

        if (id === 'email') {
            window.location.href = "mailto:" + companyEmail
        } else if (id === 'phone') {
            window.location.href = "tel:" + companyPhone
        } else if (id === 'location') {
            window.open("https://maps.google.com?q=" + companyAddress.replaceAll("\n", " ") );
        }
    }

    //segment gradient colors
    const segmentViewGradientBackgroundColors: GradientColorsProps = {
        rotationDegree: "0",
        startColor: "rgb(31,38,43)",
        startPercentage: "0%",
        endColor: "rgb(10,14,17)",
        endPercentage: "100%"
    }

    return (
        <>
            <ContactPageWrapper>
                <Header links={navigation.menu} id="start" key="start" overlayImageUrl='/assets/images/style_header_curve.svg' />

                <SegmentView id='happy-customers' extendCss='background-color: #ffffff;' minHeight='auto'>
                    <TitleView color='#000000' title={contactGeneralTitle} description={contactGeneralDescription} />

                    <ImageView src={'/vector/' + contactGeneralImage} height='380px' extendCss='margin-bottom: 100px;' />

                    <CardView id='email' iconUrl='/icons/email_theme.svg' hideShadow={true} hoverColor='#0266c3' title={companyEmailLabel} description={companyEmail} onClick={onClickContactCard} />
                    <CardView id='phone' iconUrl='/icons/phone_theme.svg' hideShadow={true} hoverColor='#0266c3' title={companyPhoneLabel} description={companyPhone} onClick={onClickContactCard} />
                    <CardView id='location' iconUrl='/icons/location_theme.svg' hideShadow={true} hoverColor='#0266c3' title={companyAddressLabel} description={companyAddress} onClick={onClickContactCard} />
                </SegmentView>

                <SegmentView id='contact-us-header' minHeight='auto'>
                {(contactTitle || contactDescription) && <TitleView title={contactTitle} subtitle={contactDescription} color='#fff' extendCss='margin: 2% 0 -10% 0;' />}
                </SegmentView>

                <SegmentView id='contact-information' minHeight='auto' extendCss='padding: 7% 0 calc(50px + 20vh) 0;'>
                    <Contact />
                </SegmentView>

            </ContactPageWrapper>
            <Footer spikesHidden={true} links={navigation.footer} social={navigation.social} copyright={copyrightText} businessId={businessId}  />
        </>
    );

}

export default ContactPage;