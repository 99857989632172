import styled from "styled-components";
import { TopBarProps } from "./TopBar";

export const TopBarWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    min-height: 80px;
    width: 100%;
    overflow-x: hidden;
    max-width: 100%;
    background: #ffffff;

    border-color: #ced4da solid 1px;

    /* background: linear-gradient(0deg, rgba(228,233,238, 1) 0%, rgba(255,255,255,1) 40%); */
    color: #ffffff;
    z-index: 10;

    .logo-text {
        display: flex;
        transition: all 0.35s;
    }

    @media (min-width: 0px) and (max-width: 700px) {
        .logo-text {
            transform: scale(0,0);
            transition: all 0.35s;
        }
    }
`;

export const TopBarInnerWrapper = styled.div`
    display: flex;
    justify-items: flex-start;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
`;

export const TopBarLogoWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    position: absolute;
`;

export const TopBarMenuItemsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const TopBarMenuListWrapper = styled.ul`
    display: flex;
    list-style-type: none;
`;

export const TopBarMenuListItemWrapper = styled.li.attrs((props: TopBarProps) => {
  return {
    extendCss: props.extendCss,
  };
})`
    display: inline-flex;
    list-style: none;
    text-align: left;
    padding: 0 16px;
    text-transform: uppercase;
    
    a {
        position: relative;
        font-size: calc(10px + 1.4vmin);
        text-decoration: none;
        font-weight: 500;
    }

    a:link, a:visited, a:active {
        color: #2b2d42;
    }

    a:hover {
        color: #000;
    }
    
    a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 3px;
        bottom: -10px;
        background-color: #0266c3;
        transform: scaleX(0);
        transition: transform 0.3s ease;
    }
    
    a:hover::before {
        transform: scaleX(1);
    }

    @media (min-width: 0px) and (max-width: 960px) {
        a {
            font-size: calc(10px + 1.3vmin);
        }
    
        padding: 0 8px;
    }

  ${(props) => props.extendCss};
`;
