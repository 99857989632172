import { ImageViewWrapper } from "./ImageView.style";

export interface ImageViewProps {
    id?: string;
    src: string;
    alt?: "image" | string;
    title?: string;
    path?: string;
    width?: string;
    height?: string;
    onClick?: () => void;
    borderRadius?: string;
    imagePadding?: string;
    extendClass?: string;
    extendCss?: string;
}

const ImageView = ({ path = "/assets/images/", width="100%", ...props }: ImageViewProps) => {
    return (
        <ImageViewWrapper id={props.id} width={width} height={props.height} borderRadius={props.borderRadius} imagePadding={props.imagePadding} onClick={props.onClick} className={props.extendClass} extendCss={props.extendCss}>
            <img src={path + props.src} alt={props.alt} />
        </ImageViewWrapper>
    );
};

export default ImageView;