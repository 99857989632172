import { useContext, useEffect, useState } from "react";
import AlertView, { AlertContentProps, AlertViewProps } from "../../ui/atoms/AlertView/AlertView";
import timeout from "../../../utils/TimeHelper";
import { SettingsContext } from "../../../App";

export interface AlertProps {
    autoHide?: boolean;
    content: AlertContentProps;
}

const Alert = ({ ...props }: AlertProps) => {
    const delayExecutionInSeconds = 5
    const delayResetInSeconds = 1.9 //according to the animation-exit time

    const { setAlert } = useContext(SettingsContext);
    const [ contents, setContents ] = useState<AlertViewProps>({
        content: props.content
    })

    useEffect(
        () => {

          const onAutoHide = async () => {
            if (contents === undefined) return

            setContents({ content: props.content, hide: true })
            
            await timeout(delayResetInSeconds * 1000);

            setContents({ content: undefined })
            setAlert?.(undefined)
          }

          let timeoutId = setTimeout(() => { onAutoHide()}, delayExecutionInSeconds * 1000);
          return () => {
            clearTimeout(timeoutId);
          };
        },
        []
    );

    return (<AlertView {...contents} />)
};

export default Alert;