import { HashLink as Link } from "react-router-hash-link";
import ImageView from "../../atoms/ImageView/ImageView";
import { TopBarInnerWrapper, TopBarLogoWrapper, TopBarMenuItemsWrapper, TopBarMenuListItemWrapper, TopBarMenuListWrapper, TopBarWrapper } from "./TopBar.style";

export interface TopBarProps {
    title?: string;
    navigationItems: Array<NavigationItemProps>;
    extendCss?: string;
}

export interface NavigationItemProps {
    icon?: string;
    label?: string;
    path: string;
}

const TopBar = ({ ...props }: TopBarProps) => {
    return (
        <TopBarWrapper>
            <TopBarInnerWrapper>
                <TopBarLogoWrapper>
                    <ImageView src="logo/leijonic_lion.svg" width="64px" alt="Leijonic" /> <ImageView src="logo/leijonic_text.svg" alt="Leijonic" height="46px" extendClass="logo-text" extendCss="margin: 0 0 0 -12px;" />
                </TopBarLogoWrapper>
                <TopBarMenuItemsWrapper>
                    <TopBarMenuListWrapper>
                    {props.navigationItems?.map((item, index) => (
                        <TopBarMenuListItemWrapper id={item + "_" + index} key={item + "_" + index} className="link" extendCss={props.extendCss}>
                            <Link key={"nav_" + item + "_" + index} to={item.path} ><b>{item.label}</b></Link>
                        </TopBarMenuListItemWrapper>
                    ))}
                    </TopBarMenuListWrapper>
                </TopBarMenuItemsWrapper>
            </TopBarInnerWrapper>
        </TopBarWrapper>
    );
};

export default TopBar;