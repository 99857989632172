import { PageInnerViewWrapper, PageViewWrapper } from "./PageView.style";

export interface PageViewProps {
    id?: string;
    children?: any;
    extendCss?: string;
    extendInnerCss?: string;
}

const PageView = ({ ...props }: PageViewProps) => {
    return (
        <PageViewWrapper extendCss={props.extendCss}>
            <PageInnerViewWrapper extendInnerCss={props.extendInnerCss}>
                {props.children}
            </PageInnerViewWrapper>
        </PageViewWrapper>
    )
};

export default PageView;