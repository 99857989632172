import { SegmentViewProps } from "../../atoms/SegmentView/SegmentView";
import Carousel from "../Carousel/Carousel";
import TopBar, { NavigationItemProps } from "../TopBar/TopBar";
import { HeaderWrapper } from "./Header.style";

export interface HeaderProps extends SegmentViewProps {
    id?: string;
    carousel?: false | boolean;
    carouselImageUrls?: string[];
    links: Array<NavigationItemProps>;
};

const Header = ({...props}: HeaderProps) => {

    const overlayImageUrl = props.carousel ? props.overlayImageUrl : "none"

    return (
        <HeaderWrapper id={props.id} key={props.id} carousel={props.carousel} overlayImageUrl={overlayImageUrl} >
            
            <TopBar navigationItems={props.links} />
            
            {props.carousel && (
                <Carousel imageUrls={props.carouselImageUrls} />
            )}
        </HeaderWrapper>
    );
};

export default Header;