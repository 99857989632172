import styled from "styled-components";
import { LoaderProps } from "./Loader";

export const LoaderParentWrapper = styled.div.attrs((props: LoaderProps) => {
  return {
      extendCss: props.extendCss,
  };
})`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;

  ${(props) => props.extendCss};
`;

export const LoaderLargeRingWrapper = styled.div.attrs((props: LoaderProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
    display: inline-flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    top: 40vh;
    z-index: 999;

    :after {
        content: " ";
        display: block;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #0a85ed transparent #0a85ed transparent;
        animation: loader-ring 1s linear infinite;
      }
    
    @keyframes loader-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    }
      
    ${(props) => props.extendCss};
  `;

  export const LoaderSmallRingWrapper = styled.div.attrs((props: LoaderProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
    display: inline-flex;
    position: fixed;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    z-index: 998;

    :after {
        content: " ";
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-color: #01baef transparent #01baef transparent;
        animation: loader-small-ring 0.4s linear infinite;
      }
    
    @keyframes loader-small-ring {
        0% {
          transform: rotate(360deg);
        }
        100% {
          transform: rotate(0deg);
        }
    }
      
    ${(props) => props.extendCss};
  `;

  export const LoaderPulseWrapper = styled.div.attrs((props: LoaderProps) => {
    return {
        extendCss: props.extendCss,
    };
  })`
    display: inline-flex;
    position: fixed;
    width: 48px;
    height: 48px;
    z-index: 997;

    :after {
        content: '';  
        box-sizing: border-box;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #0c63e7;
        animation: animloader 0.62s linear infinite;
      }
      
      @keyframes animloader {
        0% {
          transform: scale(0);
          opacity: 0.5;
        }
        100% {
          transform: scale(2.4);
          opacity: 0;
        }
      }
          
      
    ${(props) => props.extendCss};
  `;
