import { useLayoutEffect, useState } from "react";
import GoTopIcon from "../../ui/molecules/GoTopIcon/GoTopIcon";


export interface GoTopProps {
    id?: string;
}

const GoTop = ({ ...props }: GoTopProps) => {
    const [isVisible, setVisible] = useState(false);

    useLayoutEffect(() => {
        const handleScroll = () => {
            const isScrollingToBottom = window.scrollY >= window.document.body.scrollHeight - window.innerHeight * 2
            
            if (isScrollingToBottom) {
                setVisible(true)
            } else if (isVisible) {
                setVisible(false)
            }
        }
 
        window.addEventListener("scroll", handleScroll)

        return () => {
                window.removeEventListener("scroll", handleScroll);
        };
        }, [isVisible]
    );

    return (isVisible && <GoTopIcon id={props.id} />) || (<></>)
};

export default GoTop;